import { IAppConfig } from './app/shared/IAppConfig';
import { InjectionToken } from '@angular/core';

const BAAN_BACKENDURL = 'https://baanbackend.kitsdg.ch';

export const APP_CONFIG_VAL: IAppConfig = {

  // initialize Firebase
  firebaseConfig: {
    apiKey: 'AIzaSyAZRthK9d0P2SXBe3CS3BM0SpBj-NsqybI',
    authDomain: 'myhome-c5350.firebaseapp.com',
    databaseURL: 'https://myhome-c5350.firebaseio.com',
    projectId: 'myhome-c5350',
    storageBucket: 'myhome-c5350.appspot.com',
    messagingSenderId: '844342741846'
  },

  googleMaps: {
    apiKey: 'AIzaSyC-qDeqlq5HjxtXt3eQd1AKvrqHc3xLPqw'
  },

  googlePlus: {
    webClientId: '844342741846-spbougss77urg4jg1dp45u8530oc5bc9.apps.googleusercontent.com'
  },

  Bts: '2019-02-03 18:38:07 CET build 141 built on JenkinsWin',
  FbBase: '/MyHome',
  SlackWebHookUrl: 'https://hooks.slack.com/services/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',
	
 baanBackend: {
	baanHub: `${BAAN_BACKENDURL}/ythub`,
    deleteSongUrl: `${BAAN_BACKENDURL}/api/youtube/delete`,
    downloadUrl: `${BAAN_BACKENDURL}/api/youtube/download`,
    apiInfoUrl: `${BAAN_BACKENDURL}/api/youtube/apiinfo`,
    downloadMetaDataUrl: `${BAAN_BACKENDURL}/api/youtube/downloadmetadata`,
    downloadQueuedTracksUrl: `${BAAN_BACKENDURL}/api/youtube/downloadqueue`,
	uploadKmlUrl: `${BAAN_BACKENDURL}/api/kml/upload`
  }
};

export const APP_CONFIG_DI = new InjectionToken<IAppConfig>('app.config');
